<template>
  <div v-if="loading" class="d-flex justify-content-center">
    <span class="atom-spinner-wrapper">
      <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
    </span>
  </div>
  <div v-else class="information-page">
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="settingForm">
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="settingForm narrow-form">
        <div class="row mt-4">
          <div class="col-md-12 mb-2">
            <h5 class="text-primary"><strong>Basic Information</strong></h5>
          </div>
          <div class="col-md-6">
            <text-input name="BusinessName" v-model="formData.name" label="Business Name" validate="required" />
          </div>
          <div class="col-md-6">
            <phone-number-input ref="phoneInput" name="BusinessNumber" label="Default Business Number"
              v-model="formData.number" />
          </div>
          <div class="col-md-6 text-center">
            <app-image-upload @upload="uploadLogo" @delete="deleteLogo" :file="getImageUrl('logo')">
              <span slot="title">Business Logo ( 250 x 50 )</span>
            </app-image-upload>
          </div>
        </div>
        <div v-if="showIntegrations" class="row mt-4 integration-info">
          <div class="col-md-12 mb-2">
            <h5 class="text-primary"><strong>Integrations</strong></h5>
          </div>
          <div v-if="showBroadcastSelect" class="col-md-12">
            <label class="font-weight-bold">Broadcast Client <a v-b-tooltip.hover
                title="Our system will use this sendgrid client to run email broadcast. Leave this blank and we will use availale system default clinet."
                class="fa fa-question-circle tooltip-icon" /></label>
            <multiselect v-model="broadcastIntegration" :options="activeSendgridIntegrations" :multiple="false"
              :allow-empty="false" :show-labels="false" :searchable="false" track-by="id" placeholder="Select Account"
              label="name">
            </multiselect>
          </div>
          <div v-if="showInboxSelect" class="col-md-12 mt-4">
            <label class="font-weight-bold">Inbox Client <a v-b-tooltip.hover
                title="Our system will use this email/sendgrid client to send email in inbox and automation. Leave this blank and we will use availale system default clinet."
                class="fa fa-question-circle tooltip-icon" /></label>
            <div v-if="showInboxCheckbox" class="mt-2 d-flex justify-content-start">
              <div class="checkbox abc-checkbox abc-checkbox-primary">
                <input type="radio" name="inboxClientType" id="emailAccount" value="1" v-model="inboxClientType"
                  @click="onInboxClientTypeClick">
                <label for="emailAccount">
                  <span class="abc-label-text">Email Account</span>
                </label>
              </div>
              <div class="checkbox abc-checkbox abc-checkbox-primary ml-3">
                <input type="radio" name="inboxClientType" id="sendgridIntegration" value="2" v-model="inboxClientType"
                  @click="onInboxClientTypeClick">
                <label for="sendgridIntegration">
                  <span class="abc-label-text">SendGrid</span>
                </label>
              </div>
            </div>
            <div class="mt-3">
              <div v-if="inboxClientType == 2">
                <template v-if="showBroadcastSelect">
                  <multiselect v-model="inboxIntegration" :options="activeSendgridIntegrations" :multiple="false"
                    :allow-empty="false" :show-labels="false" :searchable="false" track-by="id"
                    placeholder="Select Account" label="name">
                  </multiselect>
                </template>
              </div>
              <div v-else>
                <multiselect v-model="inboxIntegration" :options="activeEmailAccounts" :multiple="false"
                  :allow-empty="false" :show-labels="false" :searchable="false" track-by="id" placeholder="Select Account"
                  label="name">
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5 credit-info">
          <div class="col-md-12 mb-2">
            <h5 class="text-primary"><strong>Credit Information</strong></h5>
          </div>
          <div class="col-md-12">
            <ul>
              <li><span>SMS Credit Per Month</span> <b>{{ business.number_of_messages_org }}</b></li>
              <li v-if="business.additional_messages"><span>SMS Credit (Extra)</span> <b>{{ business.additional_messages
              }}</b></li>
              <li><span>Call Credit Per Month</span> <b>{{ business.number_of_calls_org }}</b></li>
              <li v-if="business.additional_calls"><span>Call Credit (Extra)</span> <b>{{ business.additional_calls }}</b>
              </li>
            </ul>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="invalid" style="min-width: 80px">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto" />
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AppImageUpload from "../../common/ImageUpload";

export default {
  name: "business-information",
  components: {
    AppImageUpload,
  },
  data() {
    return {
      loading: false,
      formData: {
        name: '',
        number: '',
        images: {},
      },
      inboxClientType: 1,
      broadcastIntegration: null,
      inboxIntegration: null,
    };
  },
  mounted() {
    this.loading = true
    Promise.all([
      this.$store
        .dispatch("businessIntegration/list"),
      this.$store
        .dispatch("businessIntegration/emailAccountAll")
    ]).then(() => {
      this.initData()
      this.loading = false
    })
  },
  computed: {
    ...mapGetters("auth", {
      settings: "settings",
      user: "user",
      business: "business",
      hasSendgridAvailable: "hasSendgridAvailable",
      hasEmailClientAvailable: "hasEmailClientAvailable",
      sendgridEnabled: "sendgridEnabled",
    }),

    ...mapGetters("businessIntegration", {
      sendgridIntegrations: "sendgridIntegrations",
      emailAccounts: "emailAccounts",
    }),

    ...mapState("business", {
      processing: "processing",
    }),

    activeEmailAccounts() {
      return this.emailAccounts.filter(item => item.valid)
        .map(item => {
          return {
            id: item.id,
            name: item.email
          }
        })
    },

    activeSendgridIntegrations() {
      return this.sendgridIntegrations.filter(item => item.status)
        .map(item => {
          return {
            id: item.id,
            name: item.name
          }
        })
    },

    showBroadcastSelect() {
      return this.sendgridEnabled && this.activeSendgridIntegrations && this.activeSendgridIntegrations.length > 1
    },

    showInboxSelect() {
      return this.showInboxCheckbox ||
        this.activeEmailAccounts && this.activeEmailAccounts.length > 1 ||
        this.sendgridEnabled && this.activeSendgridIntegrations && this.activeSendgridIntegrations.length > 1
    },

    showInboxCheckbox() {
      return this.sendgridEnabled && this.hasSendgridAvailable && this.hasEmailClientAvailable
    },

    showIntegrations() {
      return this.showBroadcastSelect || this.showInboxSelect
    }
  },

  watch: {
  },

  methods: {
    uploadLogo(event) {
      this.uploadImage(event, 'logo');
    },
    deleteLogo(event) {
      this.deleteImage(event, 'logo');
    },

    uploadImage(event, type) {
      event.waitUntil(new Promise((resolve, reject) => {
        let postData = new FormData();
        postData.append('image', event.image);
        postData.append('type', type);
        let params = {
          data: postData
        }
        this.$store.dispatch('business/uploadImage', params)
          .then((data) => {
            if (data.success) {
              const image = data.image;
              this.setImageProperty(type, image);
              resolve(image.url);
            } else {
              reject();
            }
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          })
      }))
    },

    deleteImage(event, type) {
      event.waitUntil(new Promise((resolve, reject) => {
        let params = {
          type: type
        }
        this.$store.dispatch('business/deleteImage', params)
          .then((data) => {
            if (data.success) {
              this.deleteImageProperty(type);
              resolve();
            } else {
              reject();
            }
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          })
      }))
    },

    onInboxClientTypeClick() {
      this.inboxIntegration = null
    },

    initData() {
      this.formData = {
        name: this.business.name,
        number: this.business.properties.incomingCallsPhoneNumber,
        images: this.business.properties.images,
      }
      this.inboxClientType = this.business.inbox_client_type
      if (this.business.inbox_client_id) {
        if (this.inboxClientType === 1) {
          this.inboxIntegration = this.activeEmailAccounts.find(item => item.id === this.business.inbox_client_id)
        } else {
          this.inboxIntegration = this.activeSendgridIntegrations.find(item => item.id === this.business.inbox_client_id)
        }
      }
      if (this.business.broadcast_client_id) {
        this.broadcastIntegration = this.activeSendgridIntegrations.find(item => item.id === this.business.broadcast_client_id)
      }
    },

    getImageUrl(type) {
      let result = null;
      if (
        this.formData &&
        this.formData.images &&
        type in this.formData.images
      ) {
        result = this.formData.images[type].url;
      }
      return result;
    },

    setImageProperty(type, image) {
      this.formData.images[type] = image;
    },

    deleteImageProperty(type) {
      if (type in this.formData.images) {
        delete this.formData.images[type]
      }
    },

    onFormSubmit() {
      const params = {
        ...this.formData,
        inbox_client_type: this.inboxClientType,
        inbox_client_id: this.inboxIntegration ? this.inboxIntegration.id : null,
        broadcast_client_id: this.broadcastIntegration ? this.broadcastIntegration.id : null,
      };
      this.$store
        .dispatch("business/updateInfo", params)
        .then((res) => { })
        .catch((err) => { });
    },
  },
};
</script>
<style lang="scss" scoped>
.information-page {
  .credit-info {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding-bottom: 3px;

        span {
          font-weight: 600;
          display: inline-block;
          min-width: 250px;
        }
      }
    }
  }

  .checkbox-label {
    --primaryColor: #3578c6;
  }

  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked~.checkbox-custom::after {
    left: 5px;
    top: 0px;
  }
}
</style>
  